import React, {createContext, useContext, useState} from 'react';
import Util from '../../shared/util/util';
import {createSavedOptions} from '../../../the_eye_util';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import UpdateSavedReportMutation from '../../../mutations/update_saved_report_mutation';
import Moment from 'moment';
import {BUTTON_COLOR, ELEMENT_TYPE, PERIOD_TYPE} from '../../../constants';
import {TopHeaderBar} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {useIntl} from 'react-intl';
import {hasPermission} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {processSavedReport} from './GenericSavedReportConductor';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';

const GenericReportContext = createContext();

export const useGenericReportContext = () => {
	const context = useContext(GenericReportContext);
	if (!context) throw new Error('useGenericReportContext must be used within GenericReportPageProvider!');
	return context;
};

export const buildUpdatedEyeOptions = (updatedEyeOptions, currentEyeOptions) => {
	return {...currentEyeOptions, ...updatedEyeOptions};
};

export const getSavedEyeOptions = savedReport => {
	const object = JSON.parse(savedReport.eyeApplied);
	if (!object || typeof object !== 'object') {
		// If eyeOptions is not a proper object, use default eyeOptions (e.g. if stored as "No default eye options provided!")
		return {};
	}
	return object;
};

const getPeriodDate = (periodType, initialPeriodDate) => {
	switch (periodType) {
		case PERIOD_TYPE.YEAR:
			const periodDateOrDefault =
				isNaN(initialPeriodDate) || initialPeriodDate < 1972 ? Moment().format('YYYY') : initialPeriodDate;
			return parseInt(periodDateOrDefault);
		case null:
			return null;
		default:
			break;
	}
};

export const GenericReportContextProvider = ({children, savedReportRef, actualPersonId, sharedId, shareType}) => {
	const savedReport = processSavedReport(savedReportRef);
	const intl = useIntl();
	//owner id
	const reportOwner = savedReport.person;

	//state variables --- only related to save and share concept
	const [isStateModified, setIsStateModified] = useState(false);
	const [reportName, setReportName] = useState(savedReport.name);
	const [startDate, setStartDate] = useState(Moment(savedReport.startDate, 'YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(Moment(savedReport.endDate, 'YYYY-MM-DD'));
	const [theEyeOptionsChecked, setTheEyeOptionsChecked] = useState(getSavedEyeOptions(savedReport));
	const [firstDropdownValue, setFirstDropdownValue] = useState(savedReport.groupingOne);
	const [secondDropdownValue, setSecondDropdownValue] = useState(savedReport.groupingTwo);
	const [thirdDropdownValue, setThirdDropdownValue] = useState(savedReport.groupingThree);
	const [fourthDropdownValue, setFourthDropdownValue] = useState(savedReport.groupingFour);
	// filterApplied / filterValue is for the old reports, filters is stored in reportService
	// conversion is done in graphQL (FilterOptionsMapper)
	const [filterValue, setFilterValue] = useState(JSON.parse(savedReport.filterApplied));
	const [filters, setFilters] = useState(savedReport.filters);

	//this getPeriodDate function is needed because some reports don't use the yyyy-mm-dd format
	//instead they expect to get just a "partial" date, as a number, like yyyy
	const [periodDate, setPeriodDate] = useState(getPeriodDate(savedReport.periodType, savedReport.periodDate));

	const [_sharedId] = useState(sharedId);
	const [_shareType] = useState(shareType);
	const [reportId] = useState(savedReport.id);

	//handlers

	const handleSetReportName = changedName => {
		setReportName(changedName);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetStartDate = changedStartDate => {
		setStartDate(changedStartDate);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetEndDate = changedEndDate => {
		setEndDate(changedEndDate);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetPeriodDate = changedPeriodDate => {
		setPeriodDate(changedPeriodDate);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetTheEyeOptions = (selectedOption, __, ___, newOptions) => {
		if (savedReport.reportService) {
			setTheEyeOptionsChecked(createSavedOptions(newOptions));
		} else {
			setTheEyeOptionsChecked(buildUpdatedEyeOptions(createSavedOptions(newOptions), getSavedEyeOptions(savedReport)));
		}

		!isStateModified && setIsStateModified(true);
	};

	const handleSetFirstDropdownValue = changedValue => {
		setFirstDropdownValue(changedValue);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetSecondDropdownValue = changedValue => {
		setSecondDropdownValue(changedValue);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetThirdDropdownValue = changedValue => {
		setThirdDropdownValue(changedValue);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetFourthDropdownValue = changedValue => {
		setFourthDropdownValue(changedValue);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetFilterValue = changedFilters => {
		setFilterValue(changedFilters);
		!isStateModified && setIsStateModified(true);
	};

	const handleSetFilters = filters => {
		setFilters(filters);
		!isStateModified && setIsStateModified(true);
	};

	const onSuccess = () => {
		setIsStateModified(false);
		createToast({
			duration: 5000,
			message: intl.formatMessage({id: 'save_report.report_updated'}),
		});
	};

	const handlePublishReportSelect = () => {
		Util.CommitMutation(
			UpdateSavedReportMutation,
			{
				id: savedReport.id,
				eyeApplied: JSON.stringify(theEyeOptionsChecked),
				groupingOne: firstDropdownValue,
				groupingTwo: secondDropdownValue,
				groupingThree: thirdDropdownValue,
				groupingFour: fourthDropdownValue,
				startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
				endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
				periodDate: JSON.stringify(periodDate),
				filterApplied: JSON.stringify(filterValue),
				filters: filters,
				name: reportName,
				reportService: savedReport.reportService,
			},
			onSuccess
		);
	};

	const getTopReportHeader = reportNameProps => {
		const headerElements = [];

		headerElements.push({
			type: TopHeaderBar.TYPE.INPUT_REPORT_TITLE,
			intl: intl,
			value: reportName,
			userpilot: 'rename-input',
			onChange: handleSetReportName,
			...reportNameProps,
		});

		return headerElements;
	};

	const getBottomReportHeader = additionalProps => {
		const headerElements = [];

		headerElements.push({
			type: ELEMENT_TYPE.LAST_EDIT_INFO,
			name: savedReport.updatedBy?.fullName,
			date: savedReport.updatedAt,
			byYou: savedReport.updatedBy?.id === actualPersonId,
		});

		const hasReportRedesignFeature = hasFeatureFlag('reports_redesign');
		const isShared = !!savedReport.shares.edges.length;
		const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
		const isOwner = reportOwner?.id === actualPersonId;
		const hasUpdatePermission = hasPermission(PERMISSION_TYPE.INSIGHTS_UPDATE);
		// Only allow admins and owners to edit shared reports if on the Reports Redesign feature
		const canSave = hasReportRedesignFeature && isShared ? isOwner || isAdmin : isOwner || hasUpdatePermission;

		if (canSave) {
			headerElements.push({
				type: ELEMENT_TYPE.BUTTON,
				color: BUTTON_COLOR.PURPLE,
				disabled: !isStateModified,
				text: intl.formatMessage({id: 'saved_report.save_report'}),
				userpilot: 'save-button',
				callback: handlePublishReportSelect,
				dataCy: 'save-button',
			});
		}

		additionalProps &&
			additionalProps.eyeProps &&
			headerElements.push({
				type: ELEMENT_TYPE.THE_EYE,
				defaultOptions: additionalProps.eyeProps.eyeOptions,
				checkedOptions: theEyeOptionsChecked,
				onSelect: handleSetTheEyeOptions,
			});

		//filters
		additionalProps &&
			additionalProps.filterProps &&
			headerElements.push({
				type: ELEMENT_TYPE.FILTER_V4,
				operatorOptions: {allowExclude: false, allowRequireAll: false},
				...additionalProps.filterProps,
				noMenu: true,
				useSavedReport: true,
				preAppliedFilters: filterValue,
				onFiltersChange: additionalProps.onFiltersChange || handleSetFilterValue,
			});

		return headerElements;
	};

	const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

	const getIsReportOwner = personId =>
		reportOwner && ((reportOwner.active && reportOwner.id === personId) || !reportOwner.active);

	const getHasOwnerPermission = personId => isAdmin || getIsReportOwner(personId);

	const value = {
		getTopReportHeader: getTopReportHeader,
		getBottomReportHeader: getBottomReportHeader,
		isStateModified,
		reportName: reportName,
		startDate: startDate,
		endDate: endDate,
		periodDate: periodDate,
		theEyeOptionsChecked: theEyeOptionsChecked,
		setTheEyeOptionsChecked: setTheEyeOptionsChecked,
		firstDropdownValue: firstDropdownValue,
		secondDropdownValue: secondDropdownValue,
		thirdDropdownValue: thirdDropdownValue,
		fourthDropdownValue: fourthDropdownValue,
		filterValue: filterValue,
		handleSetReportName: handleSetReportName,
		handleSetStartDate: handleSetStartDate,
		handleSetEndDate: handleSetEndDate,
		handleSetPeriodDate: handleSetPeriodDate,
		handleSetTheEyeOptions: handleSetTheEyeOptions,
		handleSetFirstDropdownValue: handleSetFirstDropdownValue,
		handleSetSecondDropdownValue: handleSetSecondDropdownValue,
		handleSetThirdDropdownValue: handleSetThirdDropdownValue,
		handleSetFourthDropdownValue: handleSetFourthDropdownValue,
		handleSetFilterValue: handleSetFilterValue,
		handleSetFilters: handleSetFilters,
		handlePublishReportSelect: handlePublishReportSelect,
		isSharedView: !!_sharedId,
		shareType: _shareType,
		sharedId,
		reportId,
		reportShares: savedReport.shares,
		isReportService: hasFeatureFlag('reports_redesign') ? savedReport.reportService : false,
		getHasOwnerPermission,
		getIsReportOwner,
		reportOwner,
		privateAccess: savedReport.privateAccess,
	};

	return <GenericReportContext.Provider value={value}>{children}</GenericReportContext.Provider>;
};
