import {cloneDeep} from 'lodash';
import Util from './forecast-app/shared/util/util';
import {hasFeatureFlag} from './forecast-app/shared/util/FeatureUtil';

export const theEyeToColumns = actualEye => {
	const val = {};
	actualEye.forEach((e, index) => {
		if (e.nestedOptions) {
			e.nestedOptions.forEach((ne, neIndex) => {
				val[ne.name] = ne.checked ? index + neIndex + 2 : 0;
			});
		}
		val[e.name] = e.checked ? index + 1 : 0;
	});
	return val;
};

const optionVisitor = (option, callback, parentPath) => {
	const path = parentPath ? `${parentPath}.${option.name}` : option.name;

	callback(option, path);
	if (option.nestedOptions) {
		option.nestedOptions.forEach(nestedOption => {
			optionVisitor(nestedOption, callback, path);
		});
	}
};

export const optionTraverser = (options, callback) => {
	options.forEach(option => {
		optionVisitor(option, callback, '');
	});
};

export const getInitialOptions = (theEyeOptions, localStorageName) => {
	const options = cloneDeep(theEyeOptions);
	const locallySavedOptionsString = localStorage.getItem(localStorageName);
	if (locallySavedOptionsString) {
		const savedOptionsValues = JSON.parse(locallySavedOptionsString);
		if (typeof savedOptionsValues === 'object' && !Array.isArray(savedOptionsValues)) {
			optionTraverser(options, (option, path) => {
				if (savedOptionsValues[path] !== undefined) {
					option.checked = savedOptionsValues[path];
				} else {
					savedOptionsValues[path] = {...option};
				}
			});
		}
	}

	return options;
};

export const getInitialOptionsFromCheckedOptions = (theEyeOptions, checkedOptions, defaultFalse) => {
	const options = cloneDeep(theEyeOptions);
	if (checkedOptions) {
		if (typeof checkedOptions === 'object' && !Array.isArray(checkedOptions)) {
			optionTraverser(options, (option, path) => {
				if (checkedOptions[path] !== undefined) {
					option.checked = checkedOptions[path];
				} else if (hasFeatureFlag('reports_redesign') && defaultFalse) {
					option.checked = false;
				}
			});
		}
	}

	return options;
};

export const createSavedOptions = changedOptions => {
	const savedOptionValues = {};
	optionTraverser(changedOptions, (option, path) => {
		savedOptionValues[path] = option.checked;
	});
	return savedOptionValues;
};

export const handleChangedOptions = (changedOptions, localStorageName) => {
	const savedOptionValues = createSavedOptions(changedOptions);

	localStorageName && Util.localStorageSetItem(localStorageName, JSON.stringify(savedOptionValues));
	return changedOptions;
};
